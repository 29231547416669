.method-container {
  padding: 0.3em 0 0.3em 0;
}

.command,
.command .ant-collapse-content-box,
.command .hljs {
  background: rgb(236, 253, 246);
}

.command .ant-collapse-header {
  border: 1px solid #4efeb3;
}

.event,
.event .ant-collapse-content-box,
.event .hljs {
  background: #fffcec;
}

.event .ant-collapse-header {
  border: 1px solid #ffaf60;
}

.report,
.report .ant-collapse-content-box,
.report .hljs {
  background: rgb(240, 247, 255);
}

.report .ant-collapse-header {
  border: 1px solid #66b3ff;
}

.tag-container {
  height: 2em;
  white-space: nowrap;
  overflow: hidden;
}

.tag-container .method-tag {
  height: 100%;
  line-height: 1.8em;
  font-size: 1em;
}

.method-content {
  width: 95%;
  margin: auto;
  padding: 0.5em 0 0.5em 0;
}

.desc {
  padding: 1em 0 1em 0;
  font-size: 1.1em;
}

.code {
  border: 1px solid;
  border-radius: 0.3em;
}

.code pre {
  margin-bottom: 0;
}

.event .code {
  border-color: #ffaf60;
}
.event .code .hljs {
  background: #ffe4ca;
}

.command .code {
  border-color: #4efeb3;
}
.command .code .hljs {
  background: #ceffce;
}

.report .code {
  border-color: #66b3ff;
}
.report .code .hljs {
  background: #c4e1ff;
}

.return-desc-container {
  display: flex;
  padding: 1em 0 1em 0;
}

.return-desc-container b {
  font-size: 16px;
}

.return-desc {
  padding: 0 0 0 1em;
}
