.doc-container {
  margin: auto;
  padding: 1em 0 0 0;
}

.header-container {
  display: flex;
  flex-direction: row;
}

.header-left {
  display: flex;
  flex-direction: row;
  width: 35%;
}
.header-left p {
  padding: 0 0 0 2.5em;
  line-height: 4.4em;
}
.header-left h2 {
  line-height: 3em;
}
.header-right {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  width: 65%;
}

.search {
  text-align: center;
}

.tag-panel > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #c7f3e5;
}
